import React, { Dispatch, SetStateAction, useEffect } from "react"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"

interface Props {
  registrationUrl: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose: () => void
}

const VerificationDialog: React.FC<Props> = ({ open, setOpen, registrationUrl, onClose }) => {
  useEffect(() => {
    if (registrationUrl && !open) {
      onClose()
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="h-full w-full px-2 py-10 md:h-[800px] md:max-w-[720px] md:px-6">
        <iframe className="h-full w-full" allow="camera; microphone" src={registrationUrl} />
      </DialogContent>
    </Dialog>
  )
}

export { VerificationDialog }
