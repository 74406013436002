import React, { useEffect, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, LoadingPage, StickyShortcut } from "@/components/_uiext"
import useAuthStore from "@/stores/useAuthStore"
import useBusinessSettingStore from "@/stores/useBusinessSettingStore"
import { INVIEW_THRESHOLD, LOADING_TIMEOUT } from "@/utils/constants"

import { Button } from "@repo/ui/components/ui/button"
import { CLIENT_MSG } from "@repo/i18n"
import { ENTITY_TYPES } from "@repo/util/constant"

import EntityDetails from "./sections/EntityDetails"

const FormSchema = z
  .object({
    entity_type: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    temp_abn: z
      .string({ required_error: CLIENT_MSG.FIELD_REQUIRED })
      .min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_name: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    abn: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    acn: z.string(),
    trust_type: z.string(),
    trust_type_other: z.string(),
    gst_registered: z.string().default(""),
    principal_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    registered_place: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    entity_email: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }).email(),
    entity_contact_number: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    primary_business_activity: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
    website: z.string().url().optional()
  })
  .superRefine((data: any, ctx) => {
    const entityTypesRequiringACN = [
      ENTITY_TYPES.Company.key,
      ENTITY_TYPES.Trust.key,
      ENTITY_TYPES.SMSF.key
    ]
    if (entityTypesRequiringACN.includes(data.entity_type) && !data.acn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: CLIENT_MSG.FIELD_REQUIRED,
        path: ["acn"]
      })
    }
  })

type FormSchemaType = z.infer<typeof FormSchema>

export default function IntroducerForm() {
  const authStore = useAuthStore()
  const store = useBusinessSettingStore()

  const navigate = useNavigate()
  const initialized = useRef(false)
  const [loading, setLoading] = useState(true)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: INVIEW_THRESHOLD })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_type: "",
      entity_name: "",
      abn: "",
      acn: "",
      trust_type: "",
      trust_type_other: "",
      gst_registered: "",
      principal_place: "",
      registered_place: "",
      entity_email: "",
      entity_contact_number: "",
      primary_business_activity: "",
      website: ""
    }
  })

  const initialize = () => {
    store.getAction((data) => {
      form.reset({
        entity_type: data.entity_type ?? "",
        temp_abn: data.entity_name ?? "",
        entity_name: data.entity_name ?? "",
        abn: data.abn ?? "",
        acn: data.acn ?? "",
        trust_type: data.trust_type ?? "",
        trust_type_other: data.trust_type_other ?? "",
        gst_registered: data.gst_registered ?? false,
        principal_place: data.principal_place ?? "",
        registered_place: data.registered_place ?? "",
        entity_email: data.entity_email ?? "",
        entity_contact_number: data.entity_contact_number ?? "",
        primary_business_activity: data.primary_business_activity ?? "",
        website: data.website ?? undefined
      })

      setTimeout(() => {
        setLoading(false)
      }, LOADING_TIMEOUT)
    })
  }

  const handleCancelClick = () => {
    navigate("/dashboard")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(values, () => {
      authStore.lockAction()
      authStore.approveAction()
      navigate("/dashboard")
    })
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  return (
    <LoadingPage loading={loading}>
      <div className="flex flex-col">
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <FormProvider {...form}>
              <form className="flex w-full flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
                <div ref={ref1} id="entity-details">
                  <EntityDetails isView={authStore.user?.is_locked} />
                </div>
              </form>
            </FormProvider>
          </div>

          <StickyShortcut
            items={[
              {
                id: "entity-details",
                label: "Entity details",
                inView: inView1
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={store.loading}
            disabled={authStore.user?.is_locked}
            type="button"
            className="px-6 py-4 md:!w-auto"
            onClick={form.handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </LoadingPage>
  )
}
