import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { NumericFormat } from "react-number-format"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, MobileNumberInput, StickyShortcut } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  APPLICATION_ASSET_TYPES,
  APPLICATION_LIABILITY_TYPES,
  ENTITY_TYPES,
  TRUST_TYPES,
  YN
} from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"

import { LOADING_TIMEOUT } from "../util"
import { useAdminAppContext } from "../AdminAppContext"

const FormSchema = z.object({
  entity_assets: z
    .array(
      z.object({
        type: z.string().min(1),
        description: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        value: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED }),
        associated_debt: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        lender: z.string().optional(),
        monthly_repayment: z.coerce.number().optional()
      })
    )
    .default([]),

  entity_liabilities: z
    .array(
      z.object({
        type: z.string().min(1),
        description: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        amount_owing: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED }),
        lender: z.string().min(1, { message: CLIENT_MSG.FIELD_REQUIRED }),
        monthly_repayment: z.coerce.number({ invalid_type_error: CLIENT_MSG.FIELD_REQUIRED })
      })
    )
    .default([])
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function EntityAL() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.1 })

  const [openAssets, setOpenAssets] = useState(false)
  const [openLiabilities, setOpenLiabilities] = useState(false)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_assets: [],
      entity_liabilities: []
    }
  })

  const {
    fields: fieldsAssets,
    append: appendAssets,
    remove: removeAssets
  } = useFieldArray({
    control: form.control,
    name: "entity_assets"
  })

  const {
    fields: fieldsLiabilities,
    append: appendLiabilities,
    remove: removeLiabilities
  } = useFieldArray({
    control: form.control,
    name: "entity_liabilities"
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      entity_assets:
        data?.assets?.filter((a: any) => a.type === APPLICATION_ASSET_TYPES.Entity) ?? [],
      entity_liabilities:
        data?.liabilities?.filter((l: any) => l.type === APPLICATION_LIABILITY_TYPES.Entity) ?? []
    })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <h3 className="text-main mb-2 text-lg font-semibold">Entity’s A & L</h3>

            <p className="text-default mb-8 text-sm font-normal">
              Please enter Entity's assets & liabilities.
            </p>

            <div ref={ref1} id="entity-details">
              <Accordion type="multiple" className="w-full" defaultValue={["entity-details"]}>
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="entity-details">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex items-center justify-between gap-4">
                      <h3 className="text-main text-lg font-semibold">Entity details</h3>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col p-0">
                    <div className="mb-4 mt-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Entity type
                          </FormLabel>
                          <Select disabled value={data?.borrower?.entity_type}>
                            <SelectTrigger
                              className={clsx(
                                "h-12 w-full text-sm",
                                data?.borrower?.entity_type ? "text-main" : "text-default"
                              )}
                            >
                              <SelectValue placeholder="Please select" />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                                <SelectItem key={k} value={v.key}>
                                  {v.key}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      </div>

                      <div className="hidden flex-1 md:block" />
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:items-center md:gap-8",
                        !data?.borrower?.entity_type && "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Entity name
                          </FormLabel>
                          <Input disabled value={data?.borrower?.entity_name} />
                        </FormItem>
                      </div>

                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Goods & services tax (GST)
                          </FormLabel>
                          <Input disabled value={data?.borrower?.gst_registered} />
                        </FormItem>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                        !data?.borrower?.entity_type && "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Australian business number (ABN)
                          </FormLabel>
                          <Input disabled autoComplete="off" value={data?.borrower?.abn} />
                        </FormItem>
                      </div>

                      <div className="flex-1">
                        <div
                          className={clsx(
                            data?.borrower?.entity_type === ENTITY_TYPES.Company.key ||
                              data?.borrower?.entity_type === ENTITY_TYPES.Trust.key ||
                              data?.borrower?.entity_type === ENTITY_TYPES.SMSF.key
                              ? "block"
                              : "hidden"
                          )}
                        >
                          <FormItem>
                            <FormLabel className="text-main text-sm font-normal">
                              Australian company number (ACN)
                            </FormLabel>
                            <Input disabled autoComplete="off" value={data?.borrower?.acn} />
                          </FormItem>
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                        (!data?.borrower?.entity_type ||
                          data?.borrower?.entity_type !== ENTITY_TYPES.Trust.key) &&
                          "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Type of trust
                          </FormLabel>
                          <Select disabled value={data?.borrower?.trust_type}>
                            <SelectTrigger
                              className={clsx(
                                "h-12 w-full text-sm",
                                data?.borrower?.trust_type ? "text-main" : "text-default"
                              )}
                            >
                              <SelectValue placeholder="Please select" />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.entries(TRUST_TYPES).map(([k, v]) => (
                                <SelectItem key={k} value={v.key}>
                                  {v.key}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      </div>

                      <div className="flex-1">
                        <div
                          className={clsx(
                            data?.borrower?.trust_type === TRUST_TYPES.Other.key
                              ? "block"
                              : "hidden"
                          )}
                        >
                          <FormItem>
                            <FormLabel className="text-main text-sm font-normal">
                              Please enter your type of trust
                            </FormLabel>
                            <Input
                              disabled
                              autoComplete="off"
                              value={data?.borrower?.trust_type_other}
                            />
                          </FormItem>
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                        !data?.borrower?.entity_type && "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Principal place of business (not a PO Box)
                          </FormLabel>
                          <Input
                            disabled
                            autoComplete="off"
                            value={data?.borrower?.principal_place}
                          />
                        </FormItem>
                      </div>

                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Registered place of business
                          </FormLabel>
                          <Input
                            disabled
                            autoComplete="off"
                            value={data?.borrower?.registered_place}
                          />
                        </FormItem>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                        !data?.borrower?.entity_type && "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Entity email address
                          </FormLabel>
                          <Input
                            disabled
                            type="email"
                            autoComplete="off"
                            value={data?.borrower?.entity_email}
                          />
                        </FormItem>
                      </div>
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Entity contact number
                          </FormLabel>
                          <MobileNumberInput
                            disabled
                            prefix="0"
                            value={data?.borrower?.entity_contact_number}
                          />
                        </FormItem>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                        !data?.borrower?.entity_type && "hidden"
                      )}
                    >
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">
                            Primary business activity
                          </FormLabel>
                          <Input
                            disabled
                            autoComplete="off"
                            value={data?.borrower?.primary_business_activity}
                          />
                        </FormItem>
                      </div>
                      <div className="flex-1">
                        <FormItem>
                          <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                          <Input
                            disabled
                            type="url"
                            autoComplete="off"
                            value={data?.borrower?.website ?? ""}
                          />
                        </FormItem>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>

            <div ref={ref2} id="entity-assets">
              <Accordion type="single" className="w-full" value={openAssets ? "assets" : ""}>
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="assets">
                  <AccordionTrigger
                    className="h-24 hover:no-underline md:h-12"
                    tabIndex={-1}
                    onClick={() => setOpenAssets((prev) => !prev)}
                  >
                    <div className="flex w-full items-center justify-between gap-4 pr-4">
                      <h3 className="text-main relative text-lg font-semibold">
                        Entity assets
                        <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                          {fieldsAssets.length}
                        </div>
                      </h3>
                      <span
                        className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenAssets(true)
                          appendAssets({
                            type: APPLICATION_ASSET_TYPES.Entity,
                            description: "",
                            value: 0,
                            associated_debt: "",
                            lender: "",
                            monthly_repayment: 0
                          })
                        }}
                      >
                        Add asset
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-4 p-0">
                    {fieldsAssets.map((item, index) => (
                      <div
                        key={item.id}
                        className={clsx(
                          "flex flex-col rounded-xl border p-4",
                          index === 0 && "mt-4"
                        )}
                      >
                        <div className="mb-4 flex w-full justify-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="destructive"
                            onClick={() => removeAssets(index)}
                          >
                            Remove
                          </Button>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_assets.${index}.description`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Description of asset
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_assets.${index}.value`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Value of asset
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    placeholder="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_assets.${index}.associated_debt`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Does your asset have an associated debt?
                                  </FormLabel>
                                  <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                      className={clsx(
                                        "h-12 w-full text-sm",
                                        field.value ? "text-main" : "text-default"
                                      )}
                                    >
                                      <SelectValue placeholder="Please select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(YN).map(([k, v]) => (
                                        <SelectItem key={k} value={v}>
                                          {v}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="hidden flex-1 md:block" />
                        </div>

                        <div
                          className={clsx(
                            "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
                            form.watch(`entity_assets.${index}.associated_debt`) === YN.Yes
                              ? "block"
                              : "hidden"
                          )}
                        >
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_assets.${index}.lender`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Who is the lender?
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_assets.${index}.monthly_repayment`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Monthly repayment / limit
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    placeholder="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>

            <div ref={ref3} id="entity-liabilities">
              <Accordion
                type="single"
                className="w-full"
                value={openLiabilities ? "liabilities" : ""}
              >
                <AccordionItem className="mb-4 rounded-2xl border p-4" value="liabilities">
                  <AccordionTrigger
                    className="h-24 hover:no-underline md:h-12"
                    tabIndex={-1}
                    onClick={() => setOpenLiabilities((prev) => !prev)}
                  >
                    <div className="flex w-full items-center justify-between gap-4 pr-4">
                      <h3 className="text-main relative text-lg font-semibold">
                        Entity liabilities
                        <div className="absolute right-[-16px] top-[-4px] flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs font-normal text-white">
                          {fieldsLiabilities.length}
                        </div>
                      </h3>
                      <span
                        className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenLiabilities(true)
                          appendLiabilities({
                            type: APPLICATION_LIABILITY_TYPES.Entity,
                            description: "",
                            amount_owing: 0,
                            lender: "",
                            monthly_repayment: 0
                          })
                        }}
                      >
                        Add liability
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-4 p-0">
                    {fieldsLiabilities.map((item, index) => (
                      <div
                        key={item.id}
                        className={clsx(
                          "flex flex-col rounded-xl border p-4",
                          index === 0 && "mt-4"
                        )}
                      >
                        <div className="mb-4 flex w-full justify-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="destructive"
                            onClick={() => removeLiabilities(index)}
                          >
                            Remove
                          </Button>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_liabilities.${index}.description`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Description of liability
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_liabilities.${index}.amount_owing`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Amount owing
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    placeholder="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_liabilities.${index}.lender`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Who is the lender?
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`entity_liabilities.${index}.monthly_repayment`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Monthly repayment / limit
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    placeholder="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "entity-details",
                label: "Entity details",
                inView: inView1
              },
              {
                id: "entity-assets",
                label: "Entity assets",
                inView: inView2
              },
              {
                id: "entity-liabilities",
                label: "Entity liabilities",
                inView: inView3
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
