import React, { forwardRef, useEffect, useState } from "react"

import type { MaskitoOptions, MaskitoPreprocessor } from "@maskito/core"
import {
  maskitoAddOnFocusPlugin,
  maskitoCaretGuard,
  maskitoPrefixPostprocessorGenerator,
  maskitoRemoveOnBlurPlugin
} from "@maskito/kit"
import { useMaskito } from "@maskito/react"

import { Input } from "@repo/ui/components/ui/input"

function createCompletePhoneInsertionPreprocessor(prefix: string): MaskitoPreprocessor {
  const regex = new RegExp(`^(\\+?61?\\s?${prefix}?)?`)

  const trimPrefix = (value: string): string => value.replace(regex, "")
  const countDigits = (value: string): number => value.replaceAll(/\D/g, "").length

  return ({ elementState, data }) => {
    const { value, selection } = elementState

    return {
      elementState: {
        selection,
        value: countDigits(value) > 11 ? trimPrefix(value) : value
      },
      data: countDigits(data) >= 11 ? trimPrefix(data) : data
    }
  }
}

const generateOptions = (prefix: string) => {
  return {
    mask: [
      ...prefix.split(""),
      ...(prefix.length === 1 ? [/\d/, /\d/] : [/\d/]),
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/,
      " ",
      /\d/,
      /\d/,
      /\d/
    ],
    postprocessors: [maskitoPrefixPostprocessorGenerator(prefix)],
    preprocessors: [createCompletePhoneInsertionPreprocessor(prefix)],
    plugins: [
      maskitoAddOnFocusPlugin(prefix),
      maskitoRemoveOnBlurPlugin(prefix),
      maskitoCaretGuard((value, [from, to]) => [from === to ? prefix.length : 0, value.length])
    ]
  } satisfies MaskitoOptions
}

interface Props {
  className?: string
  prefix?: string
  disabled?: boolean
  name?: string
  value?: string | null
  onChange?: (val: string) => void
}

const MobileNumberInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ className = "", prefix = "04", disabled = false, name, value, onChange }: Props, ref) => {
    const maskedInputRef = useMaskito({ options: generateOptions(prefix) })
    const [val, setVal] = useState("")

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value)

      if (typeof onChange === "function") {
        onChange(e.target.value)
      }
    }

    useEffect(() => {
      setVal(value ?? "")
    }, [value])

    return (
      <Input
        className={className}
        disabled={disabled}
        ref={(node) => {
          maskedInputRef(node)
          if (typeof ref === "function") {
            ref(node)
          }
        }}
        placeholder={`${prefix}${prefix.length === 1 ? "_" : ""}__ ___ ___`}
        value={val}
        onInput={handleInput}
      />
    )
  }
)

export { MobileNumberInput }
