import React, { Dispatch, SetStateAction, useMemo } from "react"

import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import clsx from "clsx"
import { Oval } from "react-loader-spinner"

import { IconMyBorrowers } from "@/components/_icons"
import { ITableParams } from "@/types/interface"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@repo/ui/components/ui/table"

import Header from "./Header"
import Pagination from "./Pagination"

import "./style.css"
import useAuthStore from "@/stores/useAuthStore"
import { USER_ROLES } from "@util/src/constant"

interface Props<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  totalCount: number
  tableParams: ITableParams
  setTableParams: Dispatch<SetStateAction<ITableParams>>
}

export default function DataTable<TData, TValue>({
  loading,
  columns,
  data,
  totalCount,
  tableParams,
  setTableParams
}: Readonly<Props<TData, TValue>>) {
  const authStore = useAuthStore()

  const newColumns = useMemo(() => {
    let allColumns = columns

    if (authStore.user?.role === USER_ROLES.Employee) {
      allColumns = allColumns.filter((c: any) => c.accessorKey !== "belongs_to")
    }

    return allColumns
  }, [authStore.user?.role])

  const table = useReactTable({
    columns: newColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  })

  return (
    <div className="flex h-full w-full flex-col">
      <Header tableParams={tableParams} setTableParams={setTableParams} />

      <div className="mb-4 w-full flex-1">
        <Table className="datatable">
          <TableHeader className="relative">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="rounded-xl bg-white hover:bg-white">
                {headerGroup.headers.map((header, index) => (
                  <TableHead
                    key={header.id}
                    className={clsx(
                      "text-main border-b py-6 text-sm",
                      index === 0 ? "rounded-bl-xl border-l" : "",
                      index === headerGroup.headers.length - 1 ? "rounded-br-xl border-r" : ""
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}

            <TableRow className="h-4 bg-transparent" />
          </TableHeader>

          <TableBody className="bg-white">
            {loading && (
              <TableRow className="hover:bg-transparent">
                <TableCell
                  colSpan={columns.length}
                  className="h-40 rounded-xl border text-center align-middle"
                >
                  <div className="flex flex-col items-center justify-center">
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#08ECB3"
                      secondaryColor="#08ECB3"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              !!table.getRowModel().rows?.length &&
              table.getRowModel().rows.map((row, rowIndex) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell
                      key={cell.id}
                      className={clsx(
                        "border-b",
                        rowIndex === 0 ? "border-t" : "",
                        cellIndex === 0 ? "border-l" : "",
                        cellIndex === row.getVisibleCells().length - 1 ? "border-r" : "",
                        rowIndex === 0 && cellIndex === 0 ? "rounded-tl-xl" : "",
                        rowIndex === 0 && cellIndex === row.getVisibleCells().length - 1
                          ? "rounded-tr-xl"
                          : "",
                        rowIndex === table.getRowModel().rows?.length - 1 && cellIndex === 0
                          ? "rounded-bl-xl"
                          : "",
                        rowIndex === table.getRowModel().rows?.length - 1 &&
                          cellIndex === row.getVisibleCells().length - 1
                          ? "rounded-br-xl"
                          : ""
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

            {!loading && !table.getRowModel().rows?.length && (
              <TableRow className="hover:bg-transparent">
                <TableCell colSpan={columns.length} className="h-40 rounded-xl border text-center">
                  <div className="flex flex-col p-8">
                    <div className="mb-4 flex justify-center">
                      <div className="flex max-h-16 min-h-16 min-w-16 max-w-16 items-center justify-center rounded-full bg-[#CDCDCD]">
                        <IconMyBorrowers className="text-2xl text-white" />
                      </div>
                    </div>

                    <p className="text-default mb-4 text-center text-lg font-semibold">
                      No borrowers found
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <Pagination
        loading={loading}
        totalCount={totalCount}
        rowCount={data.length}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </div>
  )
}
