import React, { forwardRef, useEffect, useState } from "react"

import type { MaskitoOptions, MaskitoPreprocessor } from "@maskito/core"
import {
  maskitoAddOnFocusPlugin,
  maskitoCaretGuard,
  maskitoPrefixPostprocessorGenerator,
  maskitoRemoveOnBlurPlugin
} from "@maskito/kit"
import { useMaskito } from "@maskito/react"

import { Input } from "@repo/ui/components/ui/input"

function createCompletePhoneInsertionPreprocessor(prefix: string): MaskitoPreprocessor {
  const regex = new RegExp(`^${prefix}`)

  const ensureHttpsPrefix = (value: string): string =>
    value.startsWith(prefix) ? value : `${prefix}${value.replace(new RegExp(`^${prefix}`, ""), "")}`

  const trimInvalidCharacters = (value: string): string => value.replace(/[^a-zA-Z0-9:/._-]/g, "")

  return ({ elementState, data }) => {
    const { value, selection } = elementState

    return {
      elementState: {
        selection,
        value: trimInvalidCharacters(value)
      },
      data: trimInvalidCharacters(data)
    }
  }
}

const generateOptions = (prefix: string) => {
  return {
    mask: ({ value }) => {
      const urlCharactersCount = value.replace(
        /https?:\/\/|[^a-zA-Z0-9._~:/?#\[\]@!$&'()*+,;=%-]/g,
        ""
      ).length

      return [
        ..."https://".split(""),
        ...new Array(urlCharactersCount || 1).fill(/[a-zA-Z0-9._~:/?#\[\]@!$&'()*+,;=%-]/)
      ]
    },
    postprocessors: [maskitoPrefixPostprocessorGenerator(prefix)],
    preprocessors: [createCompletePhoneInsertionPreprocessor(prefix)],
    plugins: [
      maskitoAddOnFocusPlugin(prefix),
      maskitoRemoveOnBlurPlugin(prefix),
      maskitoCaretGuard((value, [from, to]) => [from === to ? prefix.length : 0, value.length])
    ]
  } satisfies MaskitoOptions
}

interface Props {
  className?: string
  prefix?: string
  disabled?: boolean
  name?: string
  value?: string | null
  onChange?: (val: string) => void
}

const URLInput: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    { className = "", prefix = "https://", disabled = false, name, value, onChange }: Props,
    ref
  ) => {
    const maskedInputRef = useMaskito({ options: generateOptions(prefix) })
    const [val, setVal] = useState("")

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value)

      if (typeof onChange === "function") {
        onChange(e.target.value)
      }
    }

    useEffect(() => {
      setVal(value ?? "")
    }, [value])

    return (
      <Input
        className={className}
        disabled={disabled}
        ref={(node) => {
          maskedInputRef(node)
          if (typeof ref === "function") {
            ref(node)
          }
        }}
        placeholder={`${prefix}`}
        value={val}
        onInput={handleInput}
      />
    )
  }
)

export { URLInput }
