import React, { Dispatch, SetStateAction, useState } from "react"

import clsx from "clsx"
import { LuCheck } from "react-icons/lu"

import { LoadingButton } from "@/components/_uiext"
import useBusinessSettingStore from "@/stores/useBusinessSettingStore"

import { VerificationDialog } from "./VerificationDialog"

interface Props {
  isVerified: boolean
  setIsVerified: Dispatch<SetStateAction<boolean>>
}

export default function Verification({ isVerified, setIsVerified }: Readonly<Props>) {
  const store = useBusinessSettingStore()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [registrationUrl, setRegistrationURL] = useState("")

  const handleVerifyIdentity = () => {
    setLoading(true)
    store.verifyAction((newData) => {
      setRegistrationURL(newData.registration_url)
      setOpen(true)
      setLoading(false)
    })
  }

  const handleClose = () => {
    store.getAction((newData) => {
      setIsVerified(newData?.is_verified)
    })
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex items-center gap-4 py-4">
        <h3 className="text-main text-lg font-semibold">ID verification</h3>

        {isVerified && <LuCheck className="text-primary text-2xl font-bold" />}
      </div>

      <div className={clsx("", isVerified ? "hidden" : "block")}>
        <LoadingButton
          loading={loading}
          disabled={loading}
          type="button"
          className="h-auto w-full rounded-xl px-6 py-4 text-sm font-semibold text-white md:w-auto"
          onClick={handleVerifyIdentity}
        >
          Verify identity
        </LoadingButton>
      </div>

      <VerificationDialog
        open={open}
        setOpen={setOpen}
        registrationUrl={registrationUrl}
        onClose={handleClose}
      />
    </div>
  )
}
