import React from "react"

import EntityDetails from "./bs/EntityDetails"
import Membership from "./bs/Membership"
import Aggregator from "./bs/Aggregator"
import AustralianCreditLicence from "./bs/AustralianCreditLicence"
import AustralianCreditRepresentative from "./bs/AustralianCreditRepresentative"
import ProfessionalIndemnityInsurance from "./bs/ProfessionalIndemnityInsurance"
import AntiMoneyLaundering from "./bs/AntiMoneyLaundering"

interface Props {
  isView?: boolean
}

export default function BusinessSettingBrokerEnterprise(props: Readonly<Props>) {
  const { isView = false } = props

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main py-4 text-xl font-semibold">Business setting information</h2>

      <div id="entity-details">
        <EntityDetails isView={isView} />
      </div>

      <div id="membership" className="mb-8">
        <Membership isView={isView} />
      </div>

      <div id="aggregator">
        <Aggregator isView={isView} />
      </div>

      <div id="australian-credit-licence" className="mb-8">
        <AustralianCreditLicence isView={isView} />
      </div>

      <div id="australian-credit-representative" className="mb-8">
        <AustralianCreditRepresentative isView={isView} />
      </div>

      <div id="professional-indemnity-insurance" className="mb-8">
        <ProfessionalIndemnityInsurance isView={isView} />
      </div>

      <div id="anti-money-laundering" className="mb-8">
        <AntiMoneyLaundering isView={isView} />
      </div>
    </div>
  )
}
