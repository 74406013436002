import React, { useState } from "react"

import { InputMask } from "@react-input/mask"
import clsx from "clsx"
import { useFieldArray, useFormContext } from "react-hook-form"
import { toast } from "sonner"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { MAX_LENDER_CONTACT_COUNT } from "@repo/util/constant"
import { CLIENT_MSG } from "@repo/i18n"
import { MobileNumberInput } from "@/components/_uiext"

interface Props {
  isView?: boolean
  fieldName: string
}

export default function Contacts(props: Readonly<Props>) {
  const { isView = false, fieldName } = props
  const form = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: fieldName
  })

  const [openAccordion, setOpenAccordion] = useState<string[]>([`${fieldName}-0`])

  const handleAddClick = () => {
    if (fields.length < MAX_LENDER_CONTACT_COUNT) {
      append({
        uuid: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        role: ""
      })

      setOpenAccordion((prev: string[]) => [...prev, `${fieldName}-${fields.length}`])
    } else {
      toast.error(CLIENT_MSG.ADMIN_LENDER_CONTACTS_LIMITED)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-main py-4 text-lg font-semibold">Key contacts</h3>

        {!isView && (
          <Button
            type="button"
            className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleAddClick}
          >
            Add
          </Button>
        )}
      </div>

      <Accordion
        type="multiple"
        className="w-full"
        value={openAccordion}
        onValueChange={setOpenAccordion}
      >
        {fields.map((item, index) => (
          <AccordionItem
            className="mb-4 rounded-2xl border p-6"
            key={item.id}
            value={`${fieldName}-${index}`}
          >
            <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
              <div className="flex flex-wrap items-center gap-4">
                <div
                  className={clsx(
                    "flex min-h-12 min-w-12 items-center justify-center rounded-full border",
                    openAccordion.includes(`${fieldName}-${index}`)
                      ? "border-primary text-primary"
                      : "border-[#CDCDCD] text-[#CDCDCD]"
                  )}
                >
                  {index + 1}
                </div>
                <div className="text-main text-lg font-semibold">Contact details</div>

                {!isView && (
                  <span
                    className="bg-destructive flex items-center gap-2 rounded-xl px-6 py-4 text-sm text-white"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </span>
                )}
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col px-1 pt-6">
              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.first_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.last_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.email`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Email address
                        </FormLabel>
                        <Input disabled={isView} type="email" autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.mobile_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Mobile number
                        </FormLabel>
                        <MobileNumberInput disabled={isView} {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`${fieldName}.${index}.role`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Role</FormLabel>
                        <Input disabled={isView} autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="hidden flex-1 md:block"></div>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
