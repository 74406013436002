import React from "react"

import clsx from "clsx"
import { format, formatISO, parseISO } from "date-fns"
import { useFieldArray, useFormContext } from "react-hook-form"
import { LuPencil } from "react-icons/lu"

import { FileUpload } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { CHECK_ITEM_TYPES, YN } from "@repo/util/constant"

interface ModifiedTooltipProps {
  item: any
}

function ModifiedTooltip(props: Readonly<ModifiedTooltipProps>) {
  const { item } = props

  return (
    <TooltipProvider disableHoverableContent delayDuration={0} skipDelayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild className="cursor-pointer">
          <div className="flex h-6 w-6 items-center justify-center rounded-full border">
            <LuPencil className="text-xs text-[#CDCDCD]" />
          </div>
        </TooltipTrigger>
        <TooltipContent className="text-default p-4">
          Last modified on{" "}
          <span className="font-semibold">
            {format(parseISO(item.modified_at), "dd/MM/yyyy 'at' h:mm aaa")}
          </span>{" "}
          by <span className="font-semibold">{item.modifier_name}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

interface Props {
  checkItemIndex: number
  modifierType: string
  modifierName: string
}

export default function SupportingDocumentsItems(props: Readonly<Props>) {
  const { checkItemIndex, modifierType, modifierName } = props
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: `checkitems.${checkItemIndex}.items`
  })

  return (
    <div className="flex flex-col">
      {fields.map((item: any, index) => (
        <div key={item.id} className="mb-4 flex w-full flex-col">
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
            <div className="text-main text-sm font-normal">{item.text}</div>

            <div className="flex items-center gap-4">
              {form.getValues(`checkitems.${checkItemIndex}.items.${index}.modifier_type`) && (
                <ModifiedTooltip
                  item={form.getValues(`checkitems.${checkItemIndex}.items.${index}`)}
                />
              )}

              <FormField
                control={form.control}
                name={`checkitems.${checkItemIndex}.items.${index}.choice`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <>
                        {(item.type === CHECK_ITEM_TYPES.Input ||
                          item.type === CHECK_ITEM_TYPES.Upload) && (
                          <div className="flex gap-2.5">
                            {[YN.Yes, YN.No].map((option) => (
                              <Button
                                key={option}
                                type="button"
                                size="sm"
                                className={clsx(
                                  "h-auto border px-6 py-4 font-semibold capitalize",
                                  field.value === option
                                    ? "border-primary"
                                    : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                                )}
                                onClick={() => field.onChange(option)}
                              >
                                {option}
                              </Button>
                            ))}
                          </div>
                        )}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex w-full md:justify-end">
            <FormField
              control={form.control}
              name={`checkitems.${checkItemIndex}.items.${index}.result`}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col md:w-auto">
                  <FormControl>
                    <>
                      {item.type === CHECK_ITEM_TYPES.YesNo && (
                        <div className="flex gap-2.5">
                          {[YN.Yes, YN.No].map((option) => (
                            <Button
                              key={option}
                              type="button"
                              size="sm"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                field.value === option
                                  ? "border-primary"
                                  : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                              )}
                              onClick={() => {
                                field.onChange(option)
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modifier_type`,
                                  modifierType
                                )
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modifier_name`,
                                  modifierName
                                )
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modified_at`,
                                  formatISO(new Date())
                                )
                              }}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}

                      {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) ===
                        YN.Yes &&
                        item.type === CHECK_ITEM_TYPES.Input && (
                          <Input
                            className="w-full md:min-w-40 md:max-w-40"
                            autoComplete="off"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.target.value)
                              form.setValue(
                                `checkitems.${checkItemIndex}.items.${index}.modifier_type`,
                                modifierType
                              )
                              form.setValue(
                                `checkitems.${checkItemIndex}.items.${index}.modifier_name`,
                                modifierName
                              )
                              form.setValue(
                                `checkitems.${checkItemIndex}.items.${index}.modified_at`,
                                formatISO(new Date())
                              )
                            }}
                          />
                        )}

                      {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) ===
                        YN.Yes &&
                        item.type === CHECK_ITEM_TYPES.Upload && (
                          <div className="w-full pt-8 md:min-w-40 md:max-w-40">
                            <FileUpload
                              {...field}
                              onChange={(v: any) => {
                                field.onChange(v)
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modifier_type`,
                                  modifierType
                                )
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modifier_name`,
                                  modifierName
                                )
                                form.setValue(
                                  `checkitems.${checkItemIndex}.items.${index}.modified_at`,
                                  formatISO(new Date())
                                )
                              }}
                            />
                          </div>
                        )}
                    </>
                  </FormControl>
                  {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) === YN.Yes && (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
